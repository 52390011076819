// const BASE_URL = "http://192.168.21.2:5004/node_api/";  
// const BASE_URL = "http://192.168.21.15:5000/node_api/";

//live
// const BASE_URL = "https://admin.onellama.com/node_api/";

//demo
const BASE_URL="https://miranda.admin.iossmlm.com/node_api/"

const DEFAULT_KEY = '26eeeb4e-9af9-44b5-a8ef-dfef6b460850';


const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import TableMain from '../Common/table/TableMain';
import RepurchaseTableFilter from '../Common/table/RepurchaseTableFilter';
import { getLastPage } from '../../utils/getLastPage';
import { Form } from 'react-bootstrap'
import Select from 'react-select'


function RequestDetailsTable(props) {
  const { t } = useTranslation();
  const headers = [
    "vin",
    // "image",
    "mobile",
    "out_of_the_pocket",
    "description",
    "date",
    'sts',
    'payment_status'
    
  ];
  const lastPage = getLastPage(props.itemsPerPage, props?.data?.totalCount);
  const [selectedOption, setselectedOption] = useState()
  const statusOptions = [
    {
      value: 0,
      label: 'Requested'
    },
    {
      value: 1,
      label: 'Accepted'
    },
    {
      value: 2,
      label: 'Processing'
    },
    {
      value: 3,
      label: 'Claimed'
    }
  ]
  const selectHandler = (value) => {
    props.setSelectedstatus(value?.value)
    setselectedOption(value)
  }
  const resetHandler=()=>{
    props.setSelectedstatus(null)
    setselectedOption(null)
  }
  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="filter_Section">
          <div className='row'>
            <div className='col-lg-3'>
              <Form.Label>{t('status')}</Form.Label>
              <div className="d-flex align-items-center">
                <Select
                  options={statusOptions}
                  onChange={selectHandler}
                  value={selectedOption}
                  className="w-100" // Ensures the select takes up full width of its container
                />
                <button className="ms-2 claim_reset_btn" onClick={resetHandler}>reset</button>
              </div>
            </div>
          </div>
        </div>


        {/* <RepurchaseTableFilter headers={headers} data={props.data?.data} type={props.type} reportHeaders={reportHeaders} /> */}
        <div className="table-responsive min-hieght-table">
          <TableMain
            headers={headers}
            data={props?.data?.rows}
            startPage={1}
            currentPage={props.currentPage}
            totalPages={lastPage}
            type={props.type}
            itemsPerPage={props.itemsPerPage}
            setItemsPerPage={props.setItemsPerPage}
            setCurrentPage={props.setCurrentPage}
            setEpinSortOptions={props.setSortOptions}
            imageClickHandler={props.imageClickHandler}
            outOfPocketAmountPay={props.outOfPocketAmountPay}

          />
        </div>
      </div>
    </div>
  )
}

export default RequestDetailsTable
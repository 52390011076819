import { OnellamaWalletService } from "../../services/onellama/onellamaWallet";
import { RegisterService } from "../../services/register/register";

export const OneLlamaTiles = async () => {
    try {
        const response = await OnellamaWalletService.callOnellamaTiles()
        return response
    } catch (error) {
        console.log(error);
    }
}

export const OnellamaStatementTable = async (page, itemsPerPage, sortBy, direction) => {
    try {
        const response = await OnellamaWalletService.CallOnellamaStatementTable(page, itemsPerPage, sortBy, direction);
        return response
    } catch (error) {
        return error.message
    }
}
export const QuoteDetailsTable = async (page, itemsPerPage, sortBy, direction) => {
    try {
        const response = await OnellamaWalletService.CallQuoteDetailsTable(page, itemsPerPage, sortBy, direction);
        return response
    } catch (error) {
        return error.message
    }
}

export const getquotePendingAmount = async (data) => {
    try {
        const response = await OnellamaWalletService.getquotePendingAmount(data)
        return response
    } catch (error) {
        console.log(error);
    }
}

export const QuoteFields = async () => {
    try {
        const response = await RegisterService.getQuoteFields()
        return response
    } catch (error) {
        console.log(error);
    }
}

export const paynow = async (data) => {
    try {
        const response = await OnellamaWalletService.paynow(data)
        return response
    } catch (error) {
        console.log(error);
    }
}

import React from 'react'
import CurrencyConverter from '../../Currency/CurrencyConverter'
import {useSelector} from 'react-redux'
function Tiles({t,tiles}) {
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
      );
      const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
      );
    return (
        <div className="ewallet_top_cnt_sction">
            <div className="row">
                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img src={"/images/ewallet-ico2.png"} alt="" />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("onellamaAmount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {currency?.symbolLeft}{" "}
                                            {CurrencyConverter(
                                              
                                                tiles?.llamaWalletAmount,
                                                conversionFactor
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img src={"/images/approved-ico.svg"} alt="" />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("remainingAmount")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {currency?.symbolLeft}{" "}
                                            {CurrencyConverter(
                                               
                                                tiles?.pendingAmount,
                                                conversionFactor
                                            )}
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-xl-3 col-md-12">
                    <div className="ewallet_top_qc_balance_box">
                        <div className="row align-items-center">
                            <div className="ewallet_top_qc_balance_box_cnt">
                                <div className="ewallet_top_qc_balance_box_cnt_ico">
                                    <img src={"/images/approved-ico.svg"} alt="" />
                                </div>
                                <div className="ewallet_top_qc_balance_box_cnt_cnt">
                                    <div className="ewallet_top_qc_balance_box_cnt_head">
                                        {t("overDue")}
                                    </div>
                                    <div className="box_amnt_dv">
                                        <div className="ewallet_top_qc_balance_box_cnt_val ewallet_top_vl">
                                            {currency?.symbolLeft}{" "}
                                            {CurrencyConverter(
                                               
                                                tiles?.overdue,
                                                conversionFactor
                                            )}
                                        </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             */}
            </div>
        </div>
    )
}

export default Tiles
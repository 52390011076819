import React, { useState, useEffect } from 'react'
import { Offcanvas, Form } from "react-bootstrap";
import { useSelector } from 'react-redux'
import CurrencyInput from "react-currency-input-field";
import CurrencyConverter from '../../Currency/CurrencyConverter';
import SubmitButton from '../Common/buttons/SubmitButton';
import Stripe from '../../views/payment/Stripe';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";


function PaynowOffcanvas({ show, t, handleClose, data, vehicleId, setpayNowBtnShowStatus }) {
    console.log("========data==============",data)
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const queryClient = useQueryClient();
    const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
    const payNow = ApiHook.CallPaynow()
    const { refetch: refetchStatementTable } = ApiHook.CallOnellamaStatementTable(1, 10, '', '', '', 'statement');
    const [selectedPaymentGateway, setselectedPaymentGateway] = useState('')
    const [stripePaymentDetails, setstripePaymentDetails] = useState({
        publicKey: '',
        clientSecretKey: ''
    })
     
    const user = useSelector(
        (state) => state?.dashboard?.appLayout?.user
      );
    const handleSelectChange = (e) => {
        const { value } = e.target
        setselectedPaymentGateway(value)
        if (value === "stripe") {
            const publicKey = data?.paymentGateways?.find((obj) => obj.slug === "stripe").PaymentGatewayDetail?.publicKey
            const stripeMetaData={
                username: user?.username,
                email: user?.email,
                plan:data?.quote,
                fullname:user?.fullname
              }
            
            const body = {
                type: "quote",
                vehicleId,
                partialPayment: true,
                metadata:stripeMetaData
            };
            stripeIntent.mutateAsync(body, {
                onSuccess: (res) => {
                    if (res.status) {
                        setstripePaymentDetails({
                            ...stripePaymentDetails,
                            publicKey,
                            clientSecretKey: res.client_secret
                        })

                    } else {
                        toast.error(res.description)
                    }
                }
            });

        }
    }

    const handleSubmit = async (paymentId, paymentIntent, paymentType) => {
        const payload = {
            paymentResponse: paymentIntent,
            vehicleId,
            totalAmount: data?.pendingAmount,
            paymentMethodslug: selectedPaymentGateway,
            partialPayment: true

        }
        const response = await payNow.mutateAsync(payload)
        console.log("============payment res", response)
        if (response.status) {
            handleClose()
            setstripePaymentDetails({
                ...stripePaymentDetails,
                publicKey: '',
                clientSecretKey: ''
            })
            setpayNowBtnShowStatus(false)
            queryClient.invalidateQueries({ queryKey: ["one-llama-tiles"] });
            refetchStatementTable();
        } else {
            
        }
    }
    console.log("==stripePaymentDetails====", stripePaymentDetails)

    return (
        <Offcanvas
            show={show}
            onHide={() => {
                handleClose();
                setstripePaymentDetails({
                    ...stripePaymentDetails,
                    publicKey: '',
                    clientSecretKey: ''
                })
            }}
            placement="end"
            id="ewalletTrnsfer"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("pay_now")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            {t("amount")}
                            <span>*</span>
                        </Form.Label>
                        <div className="amount-field input-group">
                            <Form.Control
                                as="select"
                                disabled
                                className="max-40"
                            >
                                <option value="">{currency?.symbolLeft}</option>
                            </Form.Control>
                            <CurrencyInput
                                className='pending-quote_amnt'
                                name="amount"
                                id="amount"
                                placeholder={t("amount")}
                                // value={transferData.amount}
                                disabled
                                // onValueChange={(value, id) =>
                                //     handleChange({ target: { value, id } })
                                // }
                                required
                                allowNegativeValue={false}
                                // decimalsLimit={2}
                                value={data?.pendingAmount}
                                style={{ background: "#8080802e" }}
                            />
                            <div className="number-field-invalid-feedback">
                                {/* {errorMessage.amount} */}
                            </div>
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
                            <option>{t('select_payment_method')}</option>
                            {
                                data?.paymentGateways.filter((obj) => obj.slug === "stripe").map((item, index) => {
                                    return (
                                        <option value={item.slug}>{item.name}</option>
                                    )


                                })
                            }


                        </Form.Select>
                    </Form.Group>

                    {/* <SubmitButton
                        // isSubmitting={
                        //     !isFormValid() || transferMutation.isLoading
                        // }
                        // click={handleSubmit}
                        text="submit"
                        className={"btn btn-primary submit_btn"}
                    // isLoading={transferMutation.isLoading}
                    /> */}
                </Form>
                <hr />
                <React.Fragment>
                    {
                        selectedPaymentGateway == "stripe" ?

                            stripePaymentDetails?.clientSecretKey &&
                            <div className='paynow-stripe-container'>
                                <h4 style={{ textAlign: 'center' }}>Enter Your Payment Details</h4>
                                <Stripe

                                    clientSecret={stripePaymentDetails?.clientSecretKey}
                                    handleSubmit={handleSubmit}
                                    publicKey={stripePaymentDetails?.publicKey} />

                            </div>


                            : ''
                    }
                </React.Fragment>




            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default PaynowOffcanvas
import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const OnellamaWalletService = {
    callOnellamaTiles: async () => {
        return callApi("get-onelama-tiles");
    },
    CallOnellamaStatementTable: async (page, itemsPerPage, sortBy, direction) => {
        return callApi(
            `get-onelama-statements?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
        );
    },
    CallQuoteDetailsTable: async (page, itemsPerPage, sortBy, direction) => {
        return callApi(
            `get-quotes-details-table?page=${page}&perPage=${itemsPerPage}&sortBy=${sortBy}&direction=${direction}`
        );
    },
    getquotePendingAmount: async (data) => {
        return API.post("get-pending-quote-amount", data);
    },
    paynow: async (data) => {
        return API.post("pay-pending-quote-amount", data);
    }
}
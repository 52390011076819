import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import RequestDetailsTable from '../../components/ClaimRequest/RequestDetailsTable';
import { Link } from 'react-router-dom';
import ReportOffcanvas from '../../components/ClaimRequest/ReportOffcanvas';
import { ApiHook } from '../../hooks/apiHook';
import ClaimRequestImgModal from '../../components/ClaimRequest/ClaimRequestImgModal';
import { useNavigate } from 'react-router-dom';

function ClaimRequest() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortOptions, setSortOptions] = useState({
        sortBy: "",
        direction: ""
    })
    const [showOffcanvas, setshowOffcanvas] = useState(false)
    const [showModal, setshowModal] = useState(false)
    const [pendingQuoteamntStatus, setpendingQuoteamntStatus] = useState(false)
    const [selectedImg, setselectedImg] = useState('')
    const [btnDisable, setbtnDisable] = useState(false)
    const handleClose = () => {
        setshowOffcanvas(false)
        setbtnDisable(false)
    }
    const closeModal = () => {
        setshowModal(false)
    }
    const imageClickHandler = (img) => {
        setshowModal(true)
        setselectedImg(img)
    }

    const outOfPocketAmountPay = (claimRqstId, amount, quote) => {
        navigate('/pay-out-of-the-pocket-amount', { state: { claimRqstId, amount, quote } })
    }

    const checkQuotePendingamnt = ApiHook.CallcheckUserQuotePendingamnt()
    const [selectedStatus, setSelectedstatus] = useState(null)
    const requestDetails = ApiHook.CallClaimRequestReport(currentPage, itemsPerPage, sortOptions.sortBy, sortOptions.direction, selectedStatus)

    const checkUserPendingPayment = async (quoteId) => {
        const response = await checkQuotePendingamnt.mutateAsync({ quoteId })
        console.log("=====response=====", response)
        if (response.status) {
            console.log("====checkUserPendingPayment", response?.data?.data?.pending)
            if (response?.data?.data?.pending) {
                setpendingQuoteamntStatus(true)
            }
        }
    }
    return (
        <div>
            <div className="page_head_top">{t("claim_request")}</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4 text-end">
                        <div className="dropdown btn-group top_right_pop_btn_position">
                            <Link className="top_righ_pop_btn" onClick={() => setshowOffcanvas(!showOffcanvas)}>
                                {t("report_accident")}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <RequestDetailsTable
                data={requestDetails?.data}
                type={"claim-request"}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                setSortOptions={setSortOptions}
                setSelectedstatus={setSelectedstatus}
                imageClickHandler={imageClickHandler}
                outOfPocketAmountPay={outOfPocketAmountPay}
            />

            <ReportOffcanvas
                t={t}
                show={showOffcanvas}
                handleClose={handleClose}
                requestQuoteDetailstableApi={requestDetails}
                checkUserPendingPayment={checkUserPendingPayment}
                pendingQuoteamntStatus={pendingQuoteamntStatus}
                btnDisable={btnDisable}
                setbtnDisable={setbtnDisable}
            />

            <ClaimRequestImgModal
                show={showModal}
                handleClose={closeModal}
                image={selectedImg}
            />

        </div>
    )
}

export default ClaimRequest
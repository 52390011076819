import API from "../../api/api";
import { DEFAULT_KEY, BASE_URL } from "../../config/config";
import axios from 'axios'
const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const ClaimRequestServices = {
    callActiveInsuranceVehiclesList: async () => {
        return callApi("get-active-vehicles-list");
    },
    // callReportAccident: async (data) => {
    //     const customAxios = axios.create({
    //         baseURL: BASE_URL,
    //     });

    //     // Copy the api-key and access-token headers from the API instance to customAxios
    //     customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY
    //     customAxios.defaults.headers.common["access-token"] =
    //         localStorage.getItem("access-token") || "";


    //     // Set the "Content-Type" header to "multipart/form-data"
    //     customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    //     try {
    //         const response = await customAxios.post("report-accident?type=claim_request", data)
    //         return response.data;
    //     } catch (error) {
    //         console.log("=====errdf===",error)
    //         return error["response"].data?.data;
    //     }

    // },
    callReportAccident: async (data) => {
        return API.post("report-accident", data);
    },
    callClaimRequestReport: async (page, limit, sortBy, direction, status) => {
        return callApi(`get-claim-request-table?page=${page}&perPage=${limit}&sortBy=${sortBy}&direction=${direction}&status=${status}`);
    },
    getOutOfPocketGateways: async () => {
        return callApi("get-out-of-pocket-payment-gateways");
    },
    payOutOfThePayment: async (data) => {
        return API.post("pay-out-of-pocket-amount", data);
    },
    checkUserOutPoketPaymentStatus: async (id) => {
        return callApi(`check-user-out-pocket-payment_status?claimId=${id}`);
    },
    checkUserQuotePendingamnt: async (data) => {
        return API.post("check-user-quote-pending-amount", data);
    },
}
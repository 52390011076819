import React from 'react'
import { Modal } from 'react-bootstrap';
function ClaimRequestImgModal({ show, image, handleClose }) {
  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <img src={image} style={{width:'100%'}}/>
      </Modal.Body>
    </Modal>
  )
}

export default ClaimRequestImgModal
import React from "react";
import { Modal, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const RegisterModal = ({ show, handleClose, data }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('termsAndCondition')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data && <div dangerouslySetInnerHTML={{ __html: data }}></div>}
      </Modal.Body>
    </Modal>
    // <Card>
    //   <Card.Title>{t('termsAndCondition')}</Card.Title>
    //   <Card.Body>
    //     {data}
    //   </Card.Body>
    // </Card>
  );
};

export default RegisterModal;

import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiHook } from '../../hooks/apiHook';
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import Stripe from '../../views/payment/Stripe';
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from 'react-redux'


function PayOutOfTheBox() {
    const [activeTab, setActiveTab] = useState("");
    const { t } = useTranslation()
    const location = useLocation()
    const paymentGateways = ApiHook.CallOutofPocketGateways()
    const payAmount = ApiHook.CallpayOutOfThePayment()
    const { claimRqstId, amount,quote } = location?.state
    const [selectedSlug, setselectedSlug] = useState('')
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
    const [claimId, setclaimId] = useState()
    const [stripePaymentDetails, setstripePaymentDetails] = useState({
        publicKey: '',
        clientSecretKey: ''
    })
    const [paymentMethodId, setpaymentMethodId] = useState()
    const user = useSelector(
        (state) => state?.dashboard?.appLayout?.user
    );
    const checkPaymentStatus = ApiHook.CallcheckUserOutPoketPaymentStatus(claimId)
    const handlePaymentTabClick = (id, name) => {
        setActiveTab(id)
        setselectedSlug(name)
        if (name === "stripe") {
            const publicKey = paymentGateways?.data?.find((obj) => obj.slug === "stripe").PaymentGatewayDetail?.publicKey
            const stripeMetaData={
                username: user?.username,
                email: user?.email,
                plan:quote,
                fullname:user?.fullname
              }
            
            const body = {
                type: "out_of_pocket",
                productId: claimRqstId,
                metadata:stripeMetaData
            };
            stripeIntent.mutateAsync(body, {
                onSuccess: (res) => {
                    if (res.status) {
                        setstripePaymentDetails({
                            ...stripePaymentDetails,
                            publicKey,
                            clientSecretKey: res.client_secret
                        })

                    } else {
                        toast.error(res.description)
                    }
                }
            });


        }
    }

    const { clientSecretKey, publicKey } = stripePaymentDetails
    const handleSubmit = async (paymentId, paymentResponse, paymentSlug) => {
        const body = {
            totalAmount: amount,
            claimId: claimRqstId,
            paymentMethodslug: selectedSlug,
            paymentResponse: paymentResponse,

        }
        const response = await payAmount.mutateAsync(body)
        if (response.status) {
            // setclaimId(claimRqstId)
            console.log("===checkPaymentStatus=====", checkPaymentStatus?.data)
            queryClient.invalidateQueries({ queryKey: ['claim-request-report', 1, 10, '', '', null] });
            navigate('/claim-request')
        }
    }
    const handleBack = () => {
        navigate('/claim-request')
    }
    return (
        <div>
            <div className="package_upgrade_cnt_sec">
                <div className="renew-plan">
                    <div className="row justify-content-center">
                        <div className="renewcenterBox">
                            <div className="profileBgBox">
                                <div className="row align-items-center">
                                    {/* <div className="col-lg-4 col-md-12 borderPofileStyle">
                                    
                                    </div> */}
                                    <div className="col-lg-12 col-md-12 border-prf-left">
                                        <div className="row">
                                            <div className="col-md-4 pe-0">
                                                <div
                                                    className="nav nav-tabs payment_tab_reg"
                                                    id="nav-tab"
                                                    role="tablist"
                                                >
                                                    {paymentGateways?.data?.map((tab) => (
                                                        <Fragment key={tab.id}>
                                                            <button
                                                                key={tab.id}
                                                                className={`nav-link  ${activeTab === tab.id ? "active" : ""}`}
                                                                id={`nav-${tab.id}-tab`}
                                                                data-bs-toggle="tab"
                                                                data-bs-target={`#nav-${tab.id}`}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={`nav-${tab.id}`}
                                                                onClick={() =>
                                                                    handlePaymentTabClick(tab.id, tab.slug)
                                                                }
                                                            >
                                                                <i className={tab?.icon}></i> {tab.slug}
                                                            </button>
                                                        </Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-md-8 ps-0">
                                                <div
                                                    className="tab-content p-3 border mb-5 payment__tab_cnt"
                                                    id="nav-tabContent"
                                                >
                                                    {activeTab === "" && (
                                                        <p>
                                                            <strong>
                                                                {t("preferredPaymentPlaceholder")}
                                                            </strong>
                                                        </p>
                                                    )}
                                                    {paymentGateways?.data?.map((tab) => (
                                                        <div
                                                            key={tab.id}
                                                            className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                                                                } `}
                                                            id={`nav-${tab.id}`}
                                                        >
                                                            {tab.slug === "free-joining" && (
                                                                <p>
                                                                    <strong>{t("freeJoinPlaceHolder", { type: "payment" })}</strong>
                                                                </p>
                                                            )}
                                                            {tab.slug === "stripe" && stripePaymentDetails.clientSecretKey !== '' && (
                                                                <Stripe clientSecret={clientSecretKey} handleSubmit={handleSubmit} publicKey={publicKey} />
                                                            )}
                                                            {/* {tab.slug === "e-pin" && (
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label>{t("epin")}</label>
                                                                            <MultiSelect
                                                                                disableSearch
                                                                                options={epinList}
                                                                                value={epinValues}
                                                                                onChange={handleEpinChange}
                                                                                labelledBy="Select"
                                                                                hasSelectAll={false}
                                                                                disabled={formValues.epinBalance <= 0}
                                                                                closeOnChangedValue={
                                                                                    formValues.epinBalance <= 0
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="elawwet_blance_sec">
                                                                        {epinValues.map((epinItem, index) => (
                                                                            <div
                                                                                className="elawwet_blance_row"
                                                                                key={index}
                                                                            >
                                                                                <span>{epinItem.value}</span>
                                                                                <strong>
                                                                                    {t("amount")}: {currency?.symbolLeft}
                                                                                    {CurrencyConverter(
                                                                                        epinItem.amount,
                                                                                        conversionFactor
                                                                                    )}
                                                                                </strong>
                                                                                <a
                                                                                    href="#/"
                                                                                    className="epin_ball_clear"
                                                                                    onClick={() =>
                                                                                        removeItemByIndex(index)
                                                                                    } // Call the remove function with the index
                                                                                >
                                                                                    <i className="fa fa-close"></i>
                                                                                </a>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="valid_epi_pin_box">
                                                                        {t("valid_epin_data")}
                                                                    </div>
                                                                    <div className="total_epin_detail">
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{t("total_epin_amount")}</td>
                                                                                    <td className="text-end">
                                                                                        <strong>
                                                                                            {currency?.symbolLeft}
                                                                                            {CurrencyConverter(
                                                                                                formValues?.totalEpinAmount,
                                                                                                conversionFactor
                                                                                            ) ?? 0}
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("totalAmount")}</td>
                                                                                    <td className="text-end">
                                                                                        <strong>
                                                                                            {currency?.symbolLeft}
                                                                                            {CurrencyConverter(
                                                                                                formValues?.totalAmount,
                                                                                                conversionFactor
                                                                                            )}
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{t("balance")}</td>
                                                                                    <td className="text-end">
                                                                                        <strong>
                                                                                            {currency?.symbolLeft}
                                                                                            {formValues?.epinBalance !==
                                                                                                undefined
                                                                                                ? CurrencyConverter(
                                                                                                    formValues?.epinBalance,
                                                                                                    conversionFactor
                                                                                                )
                                                                                                : CurrencyConverter(
                                                                                                    formValues?.totalAmount,
                                                                                                    conversionFactor
                                                                                                )}
                                                                                        </strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {tab.slug === "e-wallet" && (
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-2">
                                                                            <p>{`${t("ewalletBalance")} : ${currency.symbolLeft
                                                                                } ${CurrencyConverter(
                                                                                    userBalance?.data?.balanceAmount,
                                                                                    conversionFactor
                                                                                )}`}</p>
                                                                            <br />
                                                                            <label htmlFor="transactionPassword">
                                                                                {t("transaction_password")}
                                                                            </label>
                                                                            <input
                                                                                id="transactionPassword"
                                                                                type="password"
                                                                                placeholder=""
                                                                                className="form-control"
                                                                                name="transPassword"
                                                                                onChange={(e) =>
                                                                                    handleTransPassword(e.target)
                                                                                }
                                                                            />
                                                                            {transPassResposne?.success && (
                                                                                <div style={{ color: "green" }}>
                                                                                    {t(transPassResposne?.success)}
                                                                                </div>
                                                                            )}
                                                                            {transPassResposne?.error && (
                                                                                <div style={{ color: "red" }}>
                                                                                    {t(transPassResposne?.error)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <button
                                                                            href="#"
                                                                            className="btn btn-sm btn-primary mt-3"
                                                                            onClick={() => setTransPassCheck(true)}
                                                                            disabled={!transPass}
                                                                        >
                                                                            {t("apply")}
                                                                        </button>
                                                                    </div>
                                                                    <span className="error-message-validator">
                                                                        {transPassCheckData.data?.message}
                                                                    </span>
                                                                </div>
                                                            )} */}

                                                            {/* {tab.slug === "bank-transfer" && (
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group mb-2">
                                                                            <label htmlFor="fileUpload">
                                                                                {t("file_upload")}
                                                                            </label>
                                                                            <input
                                                                                id="fileUpload"
                                                                                type="file"
                                                                                placeholder=""
                                                                                className="form-control"
                                                                                name="fileUpload"
                                                                                onChange={handleFileChange}
                                                                            />
                                                                            {fileResponse?.success &&
                                                                                formValues?.bankReceipt !== undefined && (
                                                                                    <div style={{ color: "green" }}>
                                                                                        {t(fileResponse?.success)}
                                                                                    </div>
                                                                                )}
                                                                            {fileResponse?.error && (
                                                                                <div style={{ color: "red" }}>
                                                                                    {t(fileResponse?.error)}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <button
                                                                            href="#"
                                                                            className="btn btn-sm btn-primary mt-3"
                                                                            onClick={handleUpload}
                                                                            disabled={
                                                                                Upload.status === "loading" ||
                                                                                formValues?.bankReceipt ||
                                                                                document.getElementById("fileUpload")
                                                                                    ?.value === "" ||
                                                                                file === null
                                                                            }
                                                                        >
                                                                            {Upload?.isLoading
                                                                                ? "Uploading..."
                                                                                : t("upload")}
                                                                        </button>
                                                                    </div>
                                                                    {Upload?.data?.status === true &&
                                                                        formValues?.bankReceipt !== undefined && (
                                                                            <>
                                                                                <img
                                                                                    src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                                                                    alt="receipt"
                                                                                    style={{
                                                                                        width: "160px",
                                                                                        height: "160px",
                                                                                        padding: "5px",
                                                                                    }}
                                                                                />
                                                                                <button
                                                                                    className="recipt_checkout_address_btn"
                                                                                    onClick={handleDeleteBankReciept}
                                                                                >
                                                                                    <i className="fa fa-xmark"></i>
                                                                                </button>
                                                                                <div style={{ color: "green" }}>
                                                                                    {Upload?.data?.message}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    {Upload?.data?.status === false && (
                                                                        <span className="error-message-validator">
                                                                            {Upload?.data?.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            )}
                                                          
                                                            {tab.slug === "paypal" && (formValues?.totalAmount || paypalPlanId != '') && (
                                                                <>
                                                                    {(autoRenew && paypalPlanId != '') ? (
                                                                        <MyPayPalSubscriptionButton
                                                                            key={autoRenew}
                                                                            data={paymentMethods.data}
                                                                        />
                                                                    ) : (
                                                                        <MyPayPalOrderButton
                                                                            key={!autoRenew}
                                                                            price={formValues.totalAmount}
                                                                            handleSubmit={handleSubmit}
                                                                            paymentType={tab.slug}
                                                                            paymentId={tab.id}
                                                                        />
                                                                    )}

                                                                </>
                                                            )} */}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="renewalBackBtn">
                                            <button
                                                className="plan-choose-back renew_btn mt-2"
                                                onClick={handleBack}
                                            >
                                                {t("back")}
                                            </button>
                                            {
                                                selectedSlug != "stripe" &&
                                                <button
                                                    type="submit"
                                                    className="plan-choose renew_btn mt-2"
                                                    // disabled={submitButtonActive}
                                                    onClick={handleSubmit}
                                                // style={activeSlug === "stripe" || activeSlug === "paypal" ? { "display": "none" } : {}}
                                                >
                                                    {t("finish")}
                                                </button>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayOutOfTheBox
import { ClaimRequestServices } from "../../services/claimRequest/ClaimRequest";

export const getActiveVehicleList = async () => {
    try {
        const response = await ClaimRequestServices.callActiveInsuranceVehiclesList()
        return response
    } catch (error) {
        console.log(error);
        return error?.response
    }
}

export const reportAccident = async (data) => {
    try {
        const response = await ClaimRequestServices.callReportAccident(data)
        return response
    } catch (error) {
        console.log("==error===fg====",error["response"])
        console.log(error);
        return error
    }
}

export const ClaimRequestReport = async (page, limit, sortBy, direction, status) => {
    try {
        const response = await ClaimRequestServices.callClaimRequestReport(page, limit, sortBy, direction, status)
        return response
    } catch (error) {
        console.log(error);
        return error
    }
}

export const getOutOfPocketPaymentGateways = async () => {
    try {
        const response = ClaimRequestServices.getOutOfPocketGateways()
        return response
    } catch (error) {
        return error
    }
}

export const payOutOfThePayment = async (data) => {
    try {
        const response = ClaimRequestServices.payOutOfThePayment(data)
        return response
    } catch (error) {
        return error
    }
}
export const checkUserOutPoketPaymentStatus = async (id) => {
    try {
        const response = ClaimRequestServices.checkUserOutPoketPaymentStatus(id)
        return response
    } catch (error) {
        return error
    }
}
export const checkUserQuotePendingamnt=async(data)=>{
    try {
        const response = ClaimRequestServices.checkUserQuotePendingamnt(data)
        return response
    } catch (error) {
        return error
    }
}

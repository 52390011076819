import React, { useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form';
import SubmitButton from '../Common/buttons/SubmitButton';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from 'react-toastify'
import CurrencyInput from "react-currency-input-field";
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

function ReportOffcanvas({ t, show, handleClose, requestQuoteDetailstableApi, checkUserPendingPayment, pendingQuoteamntStatus, btnDisable, setbtnDisable }) {
    const {
        register,
        setValue,
        setError,
        trigger,
        watch,
        getValues,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const options = []
    const [fileErr, setfileErr] = useState('')
    const [file, setfile] = useState()
    // const [btnDisable, setbtnDisable] = useState(false)
    const getActiveInsuranceVehicles = ApiHook.CallActiveInsuranceVehicles()
    const reportAccident = ApiHook.CallReportAccident()

    const formValues = watch()
    console.log("===formValues=====", formValues)
    const onSubmit = async (data) => {
        setbtnDisable(true)
        const isValid = await trigger()
        // if (!file) return setfileErr(t('this_field_is_required'))
        if (isValid) {
            // data.file = file
            const response = await reportAccident.mutateAsync(data)
            console.log("==response=====", response)
            if (response.status) {
                handleClose()
                reset()
                setfile(null)
                await requestQuoteDetailstableApi.refetch(1, 10, '', '', null)
                toast.success(t('request_added_successfully'))
            } else {
                setbtnDisable(false)
                if (response?.data?.code === 1137) {
                    toast.error(t('request_added_already'))
                } else {
                    toast.error(t('request_added_failed'))
                }
            }
        }
    }

    const fileChangeHandler = (e) => {
        const file = e.target.files[0]
        setfile(file)
        setfileErr('')
    }
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    console.log("==errors====", errors)
    const selectChangeHandler = (e) => {
        const { name, value } = e.target
        checkUserPendingPayment(value)


    }
    console.log("==btnDisable==", btnDisable)
    return (
        <Offcanvas
            show={show}
            onHide={() => {
                handleClose();
                reset()
                setfile(null)
                setbtnDisable(false)
            }}
            placement="end"
            id="ewalletTrnsfer"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("report_accident")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    {
                        pendingQuoteamntStatus &&
                        <div className='mb-2'>
                            <Link to='/one-llama-wallet'><span>{t('pending_amnt_info')}</span></Link>
                        </div>
                    }


                    <Form.Group className="mb-2">
                        <Form.Label>{t('select_vehicle')} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Select
                            {...register("quoteId", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                            })}
                            aria-label="Default select example"
                            isInvalid={!!errors.quoteId}
                            onChange={selectChangeHandler}
                        >
                            <option value="">Select</option>
                            {
                                getActiveInsuranceVehicles?.data &&
                                getActiveInsuranceVehicles?.data?.map((option, idx) => (
                                    <option value={option.id} key={idx}>{option.vin}</option>
                                ))
                            }
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {errors.quoteId?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>{t('mobile_no')} <span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter mobile number"
                            {...register("mobile", {
                                required: {
                                    value: true,
                                    message: t("this_field_is_required"),
                                },
                                pattern: {
                                    value: /^[0-9]*$/,
                                    message: t("only_numbers_are_allowed"),
                                },
                                // maxLength: {
                                //     value: 7,
                                //     message: t("max_num_exceed")
                                // },
                                validate: (value) => !/\s/.test(value) || t("no_spaces_allowed"),
                            })}
                            isInvalid={!!errors.mobile}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.mobile?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group className="mb-2">
                        <Form.Label>
                            {t("image")}
                            <span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <Form.Control type="file" isInvalid={fileErr} onChange={fileChangeHandler} />
                        <Form.Control.Feedback type="invalid">
                            {fileErr}
                        </Form.Control.Feedback>

                    </Form.Group> */}
                    <Form.Group className="mb-2">
                        <Form.Label>
                            {t("out_of_the_box")}
                            <span style={{ color: 'red' }}>*</span>
                        </Form.Label>
                        <div className="amount-field input-group">
                            <Form.Control
                                as="select"
                                disabled
                                className="max-40"
                            >
                                <option value="">{currency?.symbolLeft}</option>
                            </Form.Control>
                            <Controller
                                name="amount"
                                control={control}
                                defaultValue={500}
                                render={({ field }) => (
                                    <CurrencyInput
                                        id="amount"
                                        value={field.value} // Ensure it uses the default value
                                        onValueChange={(value) => field.onChange(value)}
                                        allowNegativeValue={false}
                                        decimalsLimit={2}
                                        className={`form-control ${errors.amount ? 'is-invalid' : ''}`}
                                        readOnly
                                    />
                                )}
                            />
                            <Form.Control.Feedback type="invalid" className="d-block">
                                {errors.amount?.message}
                            </Form.Control.Feedback>
                        </div>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>{t("description")}<span style={{ color: 'red' }}>*</span></Form.Label>
                        <Form.Control as="textarea" rows={3}
                            {...register('desc', { required: { value: true, message: t("this_field_is_required") } })}
                            isInvalid={errors?.desc}

                        />
                        <Form.Control.Feedback type="invalid">
                            {errors?.desc?.message}
                        </Form.Control.Feedback>
                    </Form.Group>


                    <SubmitButton
                        isSubmitting={pendingQuoteamntStatus || btnDisable}
                        click={handleSubmit(onSubmit)}
                        text="submit"
                        className={"btn btn-primary submit_btn"}
                    // isLoading={transferMutation.isLoading}
                    />
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default ReportOffcanvas
import { QuotesServices } from "../../services/quotes/quotes";

export const QuoteSlotsAvailiable = async () => {
    try {
        const response = await QuotesServices.checkQuotesSlots()
        return response
    } catch (error) {
        console.log(error);
    }
}

export const purchaseQuote=async(data)=>{
    try {
        const response = await QuotesServices.purchaseQuote(data)
        return response  
    } catch (error) {
        console.log(error);
    }
}


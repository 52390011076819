import React, { useState } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import QuoteModal from "../../components/Register/QuoteModal";
import { useSelector } from 'react-redux'
import RequestQuoteModal from "./RequestQuoteModal";

const ShoppingLayout = () => {
  const { t } = useTranslation();
  const items = ApiHook.CallRepurchaseItems();
  const rqstQuoteFields = ApiHook.CallQuoteFields()
  const quoteAvaliableSlots = ApiHook.CallcheckQuotesSlots()
  console.log("==rqstQuoteFields===", quoteAvaliableSlots?.data)
  const userDetails = useSelector(
    (state) => state.dashboard?.appLayout?.user
  );

  const [showQuoteModal, setshowQuoteModal] = useState(false)
  const [requestQuoteStatus, setrequestQuote] = useState(false)
  const modalClosehandler = () => {
    setshowQuoteModal(false)
  }
  const user = { email: userDetails?.email }
  return (
    <>
      <div className="page_head_top">{t("shopping")}</div>
      {
        quoteAvaliableSlots?.data?.availableSlots > 0 &&
        <div className="ewallet_top_btn_sec">
          <div className="row justify-content-between">
            <div className="col-md-4 text-end">
              <div className="dropdown btn-group top_right_pop_btn_position">
                <Link className="top_righ_pop_btn" onClick={() => setshowQuoteModal(!showQuoteModal)}>
                  {t("request_quotes")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      }

      <ProductLists products={items?.data} />

      <RequestQuoteModal
        showModal={showQuoteModal}
        close={modalClosehandler}
        fields={rqstQuoteFields?.data}
        t={t}
        formValues={user}
        setshowQuoteModal={setshowQuoteModal}
        requestQuoteStatus={requestQuoteStatus}
        setrequestQuote={setrequestQuote}
        paymentGateways={quoteAvaliableSlots?.data?.paymentMethods}
        quotes={quoteAvaliableSlots?.data?.quotes}
        quotesData={quoteAvaliableSlots?.data}
        checkQuotesSlotApi={quoteAvaliableSlots}


      />
    </>
  );
};

export default ShoppingLayout;
